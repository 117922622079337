@import './scss/variables';
@import './scss/reset';

::-moz-selection { /* Code for Firefox */
  background: $color-blue;
}

::selection {
  background: $color-blue;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family1;
}

html,
body {
  font-family: $font-family2;
  color: $color-black;
}

h1,
pre,
p,
a {
  margin: 0;
}

h1 {
  font-size: $header-size;
}

pre {
  font-size: $sub-header-size;
}

p,
span {
  font-size: $body-size;
  font-weight: $regular;
}

a {
  font-weight: $regular;
  text-decoration: none;
  cursor: pointer;
  color: $color-white;
}

.ContentHTML {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.visible {
    img.thumbnail {
      display: none;
    }
  }

  &:not(.visible) {
    display: none;
  }
}
