@import '../../../scss/variables';

.MobilePost {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: $padding-mobile;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
    max-height: calc(100% - #{$padding * 5});
    margin: auto 0;
    object-fit: contain;
    padding-bottom: $padding-mobile;
  }
}
