@import '../../../scss/variables';

.HomePage {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;

  img,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
