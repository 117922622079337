@import '../../../scss/variables';

.PostDescription {
  position: relative;
  display: block;
  flex-direction: column;
  width: $max-width;
  z-index: 999;
  padding-left: calc(#{$padding} * 2);

  &:not(.isMobile) {
    max-width: $post-description-max-width;
  }

  &.isMobile {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: $max-width;
    padding: $padding-mobile;
    box-sizing: border-box;
    pointer-events: none;

    >h1 {  font-size: $sub-header-size;}  
  }

  p {
    &:nth-child(1) {
      font-family: $font-family2;
      font-size: $body-size;
    }

    &:nth-child(2) {
      font-family: $font-family2;
    }
  }
}
