@import '../../scss/variables';

.MediaItem {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
