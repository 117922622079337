@import '../../../scss/variables';

.AboutPage {
  height: 100%;
  width: 100%;
  padding: $padding * 2 $padding * 1.87 $padding;
  box-sizing: border-box;

  &.rotate {
    animation-name: shake;
    animation-duration: 1.5s;
    animation-direction: alternate;
    animation-delay: 0s;
    @keyframes shake {
      0% {
        transform: rotate3d(1, 1, 1, 0deg);
      }

      8% {
        transform: rotate3d(30, 600, -7, 18deg);
      }

      45% {
        transform: rotate3d(-30, -400, 8, 12deg);
      }

      85% {
        transform: rotate3d(-100, 100, -2, 6deg);
      }

      100% {
        transform: rotate3d(1, 1, 1, 0deg);
      }
    }
  }

  h1,
  h2 {
    font-size: $sub-header-size;
    padding-left: 2.5rem;
  }

  h3 {
    padding-left: $padding * 1.13;
    font-size: $body-size;
    line-height: 2.1rem;
  }

  p {
    font-size: $body-size;
    padding-left: $padding * 1.13;
    margin-bottom: $margin-bottom;
    text-align: left;
    line-height: 2.1rem;
    max-width: $text-width;
  }

  a span {
    color: #151515;

    &:hover {
      transition: all 300ms ease;
      transform: translateX(5px);
    }
  }

  ul.list {
    padding-left: $padding;
    font-size: $body-size;
    line-height: 2.1rem;
    padding-left: 4.55rem;
    max-width: $text-width;
    list-style-type: hebrew;
  }

  ul:not(.social):not(.list) {
    margin: 0;
    padding: 0;
    margin-bottom: $margin-bottom;

    li {
      display: flex;
      line-height: 2.1rem;
      max-width: $text-width + 50px;

      span {
        display: inline-block;
        overflow: hidden;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 10px;
        line-height: 2.1rem;
        font-size: $body-size;
        // year
        &:nth-child(1) {
          text-align: right;
          max-width: 35px;
          min-width: 35px;
          flex-grow: 1;
        }
        // title
        &:nth-child(2) {
          width: 45%;
        }
        // place
        &:nth-child(3) {
          width: 35%;
        }
        // city
        &:nth-child(4) {
          min-width: 72px;
          padding-right: 0;
        }
      }
    }
  }

  .social {
    list-style-type: none;
    padding-left: 2.3rem;

    li {
      display: flex;
    }

    a {
      display: flex;
      align-items: baseline;
      padding: 0;
      box-sizing: border-box;
      color: $color-black;

      span {
        font-size: $body-size;
      }
    }

    p {
      margin: 0;
      padding: 0;
      display: inline-block;
    }

    img {
      margin-right: 10px;
      position: relative;
      top: 0.2rem;
      width: $social-icon-size;
      height: $social-icon-size;

      &.schutz {
        top: 0.2rem;
      }
    }
  }

  &.isMobile {
    max-width: initial;
    padding: $padding*2 $padding-mobile $padding;
  }
}
