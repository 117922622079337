@import '../../../scss/variables';

.WorksPage {
  overflow: hidden;

  .PostsSection {
    height: 100vh;

    &.isMobile {
      height: var(--window-height);
    }
  }

  .next-post {
    position: absolute;
    bottom: 0;
    left: $padding/3;
    box-sizing: border-box;
    z-index: 999;
    cursor: pointer;

    img {
      position: relative;
      padding: $padding;
      width: $navigation-arrow-size;
      transform: translateY($navigation-arrow-size / 2);
    }
  }
}
