// Globals
$margin: 5rem;
$margin-bottom: 2rem;
$padding: 4rem;
$padding-plaisier: $padding * 3;
$max-width: 100%;
$max-height: 100%;
$padding-mobile: $padding / 2;

// Index page
$post-description-max-width: 250px;
$thumbnail-max-width: 13vw;
$thumbnail-max-height: $thumbnail-max-width;
$index-gutter: $margin / 4;

// Transitions
$transition-speed: 350ms;

// Breakpoints
$breakpoint: 768px;

// Typography
$font-family1: 'Gotham', sans-serif;
$font-family2: 'LabGrotesque', sans-serif;
$header-size: 1.8rem;
$sub-header-size: 1.5rem;
$menu-size: 2rem;
$body-size: 1.4rem;
$about-table-size: 1.4rem;
$regular: 100;
$text-width: 550px;

// Navigation (arrows)
$navigation-arrow-size: $padding / 2;

// Image size
$social-icon-size: 12px;

// Colors
$color-white: rgba(248, 248, 248, 1);
$color-black: rgba(30, 30, 30, 1);
$color-blue: rgba(255, 130, 170, 0.5);
