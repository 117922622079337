html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica';
  background-color: $color-white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

html {
  font-size: 62.5%;
}
