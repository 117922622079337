@import '../../../scss/variables';

.IndexPage {
  position: relative;
  width: 100vw;
  top: 0;
  left: 0;
  overflow: hidden;
  padding: $padding * 2 $padding $padding;
  box-sizing: border-box;

  .PostDescription {
    position: fixed;
    top: 50%;
    left: $padding;
  }

  &.isSafari {
    .PostDescription {
      background-color: $color-white;
    }
  }

  h1 {
    padding-bottom: 1rem;
  }

  .columns {
    display: flex;
    flex-direction: row;
    margin-right: $padding;
    float: right;
    padding-left: $post-description-max-width;
    max-width: 65vw;

    .thumbnails {
      position: relative;
      display: inline-block;
      width: $thumbnail-max-width;
      $thumbnail-padding: 20px;

      .empty,
      .link {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: $thumbnail-padding;
        width: calc(100% - #{$thumbnail-padding});
        height: calc(#{$thumbnail-max-height} - #{$thumbnail-padding});
        margin-bottom: calc(#{$index-gutter} - #{$thumbnail-padding});
        transition: all $transition-speed / 3;
        transform: scale(1);

        img {
          max-width: 100%;
          max-height: 100%;
          transition: all $transition-speed / 3;
          transition: all $transition-speed / 3;
          box-shadow: $color-white 0 0 $thumbnail-padding -5px;
        }

        &:hover {
          transform: scale(1.05);

          img {
            box-shadow: lighten($color-black, 90%) 0 0 $thumbnail-padding -25px;
          }
        }
      }

      &:not(:last-child) {
        margin-right: $index-gutter;
      }
    }
  }

  .arrow-down {
    position: fixed;
    bottom: 0;
    left: $padding/3;
    box-sizing: border-box;
    z-index: 999;
    cursor: pointer;

    img {
      position: relative;
      padding: $padding;
      width: $navigation-arrow-size;
      transform: translateY($navigation-arrow-size / 2);
    }
  }
}
