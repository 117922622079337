@import '../../scss/variables';

.Menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  padding: calc(4rem - 14px) $padding+1.4rem $padding;
  font-size: $menu-size;
  font-family: $font-family1;
  box-sizing: border-box;
  user-select: none;

  a,
  h1 {
    display: inline-block;
    margin-right: $margin / 2;
    color: $color-black !important;
    font-size: $menu-size;
    font-family: $font-family1;
    transition: all 350ms ease;
    border-top: 2px solid transparent;

    > a {
      display: inline-block;
      font-size: $menu-size;
      font-family: $font-family1;
      margin: 0 !important;
      color: $color-black;
    }

    &:hover:not(:first-child) {
      transform: translateY(-2px);
      border-top: 2px solid $color-black;
    }
  }

  &.isMobile {
    background-color: $color-white;
    width: calc(100% - 10px);
    display: flex;
    justify-content: space-between;
    padding: $padding-mobile * 0.75 $padding-mobile * 1.2;
    padding-right: calc(#{$padding-mobile} - 10px);
    margin-right: 10px;

    > a,
    h1 {
      display: inline-block;
      margin-top: 1rem;
      margin-left: 1rem;
      margin-right: 1.5rem;
      font-size: $menu-size * 1.1;
    }
  }
}
