@import '../../scss/variables';

.Post {
  position: relative;
  width: $max-width;
  height: $max-height;
  box-sizing: border-box;

  .swiper {
    width: $max-width;
    height: $max-height;

    .swiper-slide {
      -webkit-transform: translate3d(0, 0, 0);
      -webkit-backface-visibility: hidden;
      display: flex;
      box-sizing: border-box;
      padding-top: $padding;
      padding-bottom: $padding;

      img {
        max-width: $max-width;
        object-fit: contain;
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      padding-left: $padding-plaisier;
      padding-right: calc(#{$padding-plaisier * 0.66});
      box-sizing: border-box;
      max-width: 45%;
      pointer-events: none;

      h1 {
        padding-top: calc(50vh - #{$header-size * 2});
        padding-bottom: 1rem;
      }

      p {
        font-size: $body-size;
        padding: 0;
      }
    }

    &.gallery {
      .swiper-slide:first-child {
        margin-right: 0 !important;
      }
    }

    &:not(.gallery):not(.reverse) {
      .swiper-slide:last-child {
        padding-right: $padding-plaisier;
        min-width: 55%;
      }
    }

    &.reverse {
      padding-right: $padding-plaisier;

      .text {
        padding-left: $padding * 2;
        padding-right: $padding-plaisier;
      }

      .swiper-slide:first-child {
        padding-left: $padding-plaisier;
        padding-right: $padding;
      }
    }

    &.alignBottom {
      .swiper-slide:last-child {
        padding-bottom: 0;

        img {
          height: $max-height;
          align-self: flex-end;
        }
      }
    }

    &.sizeMedium:not(.alignBottom) .swiper-slide:not(.text) {
      padding-top: $padding * 2.5;
      padding-bottom: $padding * 2.5;
    }

    &.sizeSmall:not(.alignBottom) .swiper-slide:not(.text) {
      padding-top: $padding * 5;
      padding-bottom: $padding * 5;
    }

    &.manual {
      padding-right: 0;

      .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        pointer-events: all;
      }
    }
  }

  .next-slide {
    position: absolute;
    bottom: 0;
    right: $padding/3;
    box-sizing: border-box;
    z-index: 999;
    cursor: pointer;

    img {
      position: relative;
      padding: $padding;
      height: $navigation-arrow-size;
      transform: translateY($navigation-arrow-size / 2);
    }
  }

  .additionalContent {
    margin-top: 15px;
    background-color: yellow;
  }
}
